<template>
  <div
    style="height:100%"
    class="d-flex flex-column justify-center align-center"
  >
    <div class="d-block">
      <v-icon size="40">
        mdi-tools
      </v-icon>
    </div>
    <div class="d-block font-italic">
      Widget Under Construction
    </div>
  </div>
</template>

<script>
import WidgetMixin from '../../../mixins/WidgetMixin';

export default {
  name: 'NullWidget',
  mixins: [WidgetMixin],

};
</script>
